import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { useStore } from '../../lib/store/store';

export default function RouteWatcher() {
	const setLastRoute = useStore(state => state.setLastRoute);
	const { asPath } = useRouter();
	const [lastPath, setLastPath] = useState('');

	//only store it when we had the id interpolated in the route.
	const hasInterpolated = (route: string) => {
		return /^[^\[\]]+$/.test(route);
	};

	useEffect(() => {
		if (asPath !== lastPath && hasInterpolated(asPath)) {
			setLastRoute(lastPath);
			setLastPath(asPath);
		}
	}, [lastPath, asPath, setLastRoute]);
	return null;
}
