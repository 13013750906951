import classnames from 'classnames';
import React from 'react';

type Props = {
	large?: boolean;
};

export default function LoadingSpinner({ large = false }: Props) {
	return (
		<div
			className={classnames('loading-spinner', {
				large,
			})}
		/>
	);
}
