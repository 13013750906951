import axios from 'axios';
import AuthCheckRequest from './requests/Auth/AuthCheckRequest';

export const api = (version: string = 'v1') => {
	const client = axios.create({
		baseURL: `${process.env.NEXT_PUBLIC_API_URL}/${version}/control/`,
		headers: {
			'Content-Type': 'application/json',
		},
		withCredentials: true,
	});

	axios.interceptors.response.use(
		response => {
			return response;
		},
		() => {
			AuthCheckRequest()
				.then(() => {
					window.location.reload();
				})
				.catch(error => {
					if (error.response.status === 401) {
						window.location.reload();
					}
					return error;
				});
		},
	);

	return client;
};
