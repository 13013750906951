import React, { ReactNode, MouseEvent } from 'react';
import classnames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ButtonProps = {
	primary?: boolean;
	secondary?: boolean;
	tertiary?: boolean;
	error?: boolean;
	hold?: boolean;
	disabled?: boolean;
	proceed?: boolean;
	onClick: (event: MouseEvent) => void;
	children: ReactNode;
	type?: 'button' | 'reset' | 'submit';
	ariaLabel: string;
	testId?: string;
	icon?: IconProp;
	iconPosition?: 'left' | 'right';
	id?: string;
	extraClasses?: string;
	white?: boolean;
};

export default function Button({
	primary = false,
	secondary = false,
	tertiary = false,
	error = false,
	proceed = false,
	hold = false,
	onClick,
	children,
	disabled = false,
	type = 'button',
	ariaLabel,
	icon,
	iconPosition = 'right',
	testId = undefined,
	id,
	extraClasses = '',
	white = false,
}: ButtonProps): JSX.Element {
	return (
		<button
			data-testid={testId}
			id={id || ariaLabel}
			className={classnames(`btn`, {
				primary,
				secondary,
				tertiary,
				disabled,
				error,
				proceed,
				white,
				hold,
				extraClasses,
			})}
			onClick={event => {
				if (disabled) return;
				onClick(event);
			}}
			type={type}
			disabled={disabled}
			aria-label={ariaLabel}
			role='button'
		>
			{icon && iconPosition === 'left' && (
				<FontAwesomeIcon icon={icon} className='mr-2 my-auto' fixedWidth />
			)}
			{children}
			{icon && iconPosition === 'right' && (
				<FontAwesomeIcon icon={icon} className='ml-2 my-auto' fixedWidth />
			)}
		</button>
	);
}
