import create from 'zustand';
import createBase from 'zustand/vanilla';
import { combine } from 'zustand/middleware';
import { Bucket, Action } from '../types';
import { featureKeys } from '../../components/FeatureRequestModal/features';

export const baseStore = createBase(
	combine(
		{
			unsavedChanges: false,
			showTrialModal: false,
			loading: false,
			loadingText: '',
			showActionModal: null as
				| null
				| (Partial<Action> & {
						bucketId?: string;
						bucketType?: string;
						canUpdate: boolean;
				  }),
			showBucketModal: null as
				| null
				| (Partial<Bucket> & { canUpdate: boolean }),
			showCandidateCreationModal: false,
			showManageEventModal: false as
				| false
				| {
						event?: string;
						disabled: boolean;
				  },
			showPreviewEventModal: false as boolean | string,
			showCreateJobModal: { show: false, requisition: false },
			showAddFeatureRequestModal: false as false | featureKeys,
			applicationCardType: 'Detailed',
			lastRoute: '',
			disableJobRefetch: false,
			redirectPath: '',
			showRequestStryveSourceModal: false,
		},
		set => ({
			setUnsavedChanges: (value: boolean) => {
				set(() => ({
					unsavedChanges: value,
				}));
			},
			setShowTrialModal: (value: boolean) => {
				set(() => ({
					showTrialModal: value,
				}));
			},
			setLoading: (value: boolean, loadingText?: string) => {
				set(() => ({
					loading: value,
					loadingText: loadingText || '',
				}));
			},
			setShowActionModal: (
				value:
					| (Partial<Action> & {
							bucketId?: string;
							bucketType?: string;
							canUpdate: boolean;
					  })
					| null,
			) => {
				set(() => ({
					showActionModal: value,
				}));
			},
			setShowBucketModal: (
				value: (Partial<Bucket> & { canUpdate: boolean }) | null,
			) => {
				set(() => ({
					showBucketModal: value,
				}));
			},
			setApplicationCardType: (value: string) => {
				set(() => ({
					applicationCardType: value,
				}));
			},
			setShowCandidateCreationModal: (value: boolean) => {
				set(() => ({
					showCandidateCreationModal: value,
				}));
			},
			setShowPreviewEventModal: (value: string | boolean) => {
				set(() => ({
					showPreviewEventModal: value,
				}));
			},
			setShowManageEventModal: (
				data:
					| {
							event?: string;
							jobId?: string;
							candidateId?: string;
							candidateRef?: string;
							disabled: boolean;
					  }
					| false,
			) => {
				set(() => ({
					showManageEventModal: data,
				}));
			},
			setShowCreateJobModal: (value: {
				show: boolean;
				requisition: boolean;
			}) => {
				set(() => ({
					showCreateJobModal: value,
				}));
			},
			setShowAddFeatureRequestModal: (value: false | featureKeys) => {
				set(() => ({
					showAddFeatureRequestModal: value,
				}));
			},
			setLastRoute: (lastRoute: string) => set({ lastRoute }),
			setDisableJobRefetch: (value: boolean) => {
				set({
					disableJobRefetch: value,
				});
			},
			setRedirectPath: (redirectPath: string) => set({ redirectPath }),
			setShowRequestStryveSourceModal: (value: boolean) => {
				set(() => ({
					showRequestStryveSourceModal: value,
				}));
			},
			closeAllModals: () => {
				set(() => ({
					showCreateJobModal: { show: false, requisition: false },
					showCandidateCreationModal: false,
					showManageEventModal: false,
					showRequestStryveSourceModal: false,
				}));
			},
		}),
	),
);

export const useStore = create(baseStore);
