import { ElementType } from 'react';
import '../styles/globals.css';
import Layout from './layout';

function App({
	Component,
	pageProps,
}: {
	Component: ElementType;
	pageProps: {};
}) {
	return (
		<Layout>
			<Component {...pageProps} />
		</Layout>
	);
}

export default App;
