import classnames from 'classnames';
import React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export default function LoadingOverlay({
	text,
	full = true,
}: {
	text?: string;
	full?: boolean;
}) {
	return (
		<div
			className={classnames(
				'top-0 left-0 z-[61] flex flex-col items-center justify-center',
				{
					'absolute w-full h-full': full === false,
					'fixed w-screen h-screen': full === true,
				},
			)}
		>
			<div className='absolute w-full h-full bg-pink-500 top-0 left-0 opacity-50' />
			<LoadingSpinner large={true} />
			{text && (
				<div className='flex justify-center subheading pt-4 text-xl max-w-3/4'>
					<h1
						className='flex items-center justify-center bg-white rounded-lg p-6 text-center min-w-96'
						style={{ maxWidth: '60%' }}
					>
						{text}
					</h1>
				</div>
			)}
		</div>
	);
}
