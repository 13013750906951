import { api } from '../../ApiHandler';
import { CompleteUser } from '../../../types';

export default function AuthCheckRequest(): Promise<{
	data: CompleteUser;
}> {
	return api()
		.get('auth/check')
		.then(response => response.data);
}
