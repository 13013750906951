import React, { ReactNode } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/nextjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ToastContainer, Slide } from 'react-toastify';
import Head from 'next/head';
import { useStore } from '../lib/store/store';
import LoadingOverlay from '../components/LoadingOverlay/LoadingOverlay';
import { LogOutRequest } from '../lib/api/requests/Auth/LogOutRequest';
import ErrorScreen from '../components/ErrorScreen/ErrorScreen';
import AuthCheckRequest from '../lib/api/requests/Auth/AuthCheckRequest';
import { successToast } from '../components/Toast/successToast';
import isOpenPathname from '../lib/utils/isOpenPathname';
import RouteWatcher from '../components/RouteWatcher/RouteWatcher';
import '@fortawesome/fontawesome-svg-core/styles.css';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 120000,
			refetchOnWindowFocus: false,
			retry: false,
		},
		mutations: {
			onSuccess: () => {
				successToast();
			},
		},
	},
});

// set query defaults for auth check/user query.
queryClient.setQueryDefaults('user', {
	queryFn: AuthCheckRequest,
	staleTime: 108000, // 30mins
	cacheTime: 108000, // 30mins
	refetchInterval: 100800, // 30mins
	refetchOnWindowFocus: false,
	onError: () => {
		if (!isOpenPathname(window.location.pathname)) {
			LogOutRequest()
				.then(() => {
					window.location.reload();
				})
				.catch(() => {
					window.location.reload();
				});
		}
	},
});
queryClient.setQueryDefaults('jobs', {
	staleTime: 36000,
});
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s',
		s: 'Just now',
		m: '1 min ago',
		mm: '%d mins ago',
		h: '1 hour ago',
		hh: '%d hours ago',
		d: '1 day ago',
		dd: '%d days ago',
		M: '1 month ago',
		MM: '%d months ago',
		y: '1 year ago',
		yy: '%d years ago',
	},
});

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	environment: process.env.NODE_ENV,
	ignoreErrors: [
		'ResizeObserver loop limit exceeded',
		'ResizeObserver loop completed with undelivered notifications.',
		'Non-Error promise rejection captured with value: Abort route change. Please ignore this error.',
	],
});

export default function Layout({ children }: { children: ReactNode }) {
	const loading = useStore(state => state.loading);
	const loadingText = useStore(state => state.loadingText);
	return (
		<>
			<Head>
				<meta charSet='utf-8' />
				<link rel='icon' href='/favicon.ico' />
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<meta name='theme-color' content='#000000' />
				<meta property='og:title' content='Stryve' />
				<meta
					name='description'
					content='Stryve is a powerful next-gen Applicant Tracking System'
				/>
				<meta
					name='og:description'
					content='Stryve is a powerful next-gen Applicant Tracking System'
				/>
				<meta name='og:locale' content='en_GB' />
				<meta name='og:site_name' content='Stryve' />
				<meta name='og:image' content='/images/opengraph.png' />

				<meta property='og:title' content='Stryve' />
				<meta property='og:type' content='website' />

				<link rel='apple-touch-icon' href='/images/logo-192.png' />
				<link rel='manifest' href='/manifest.json' />
				<title>Stryve</title>
			</Head>
			<Sentry.ErrorBoundary fallback={ErrorScreen}>
				<QueryClientProvider client={queryClient}>
					<div id='app' className='max-w-screen max-h-screen'>
						{children}
						{loading && <LoadingOverlay text={loadingText} />}
						<ToastContainer limit={5} autoClose={3000} transition={Slide} />
						<RouteWatcher />
					</div>
					<ReactQueryDevtools />
				</QueryClientProvider>
			</Sentry.ErrorBoundary>
		</>
	);
}
